import { Admin, NormalUser } from './Role';

export const permissions = {
  memberPage: [Admin, NormalUser],
  attendancePage: [Admin, NormalUser],
  userPage: [Admin],
};

export const permissions_string = {
  createUser: 'Create-user',
  editUser: 'Edit-user',
  deleteUser: 'Delete-user',
  createMember: 'Create-member',
  editMember: 'Edit-member',
  deleteMember: 'Delete-member',
  enableStudent: 'Enable-student-edit',
};
