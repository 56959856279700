import { Button, Divider, Form, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { MemberDetail } from '../Admin/MemberList/Drawer/MemberDetail'
import { EditOutlined } from '@ant-design/icons'
import { MemberForm } from '../Admin/MemberList/Form/MemberForm'
import { DrawerStatus, useDrawerStatus, useSetDrawerStatus } from '../../store'
import { DrawerExtra } from '../Admin/MemberList/Drawer/DrawerExtra'
import { editMember, getEnableStudentEdit } from '../../api'
import { title } from '../../constants/title'

interface Props {
  memberInfo: any;
}

export const MemberInfo = (props: Props) => {
  const { memberInfo } = props;
  const [selectedMember, setSelectedMember] = useState(memberInfo);
  const [loading, setLoading] = useState(false);
  const [isEnableEdit, setIsEnableEdit] = useState(true);
  const [form] = Form.useForm();
  const setDrawerStatus = useSetDrawerStatus();
  const drawerStatus = useDrawerStatus();

  useEffect(() => {
    async function getEnable() {
      const response = await getEnableStudentEdit();
   
      if (response?.data?.value === 'true') {
        setIsEnableEdit(true);
      } 
      else if (response?.data?.value === 'false') {
        setIsEnableEdit(false);
      }
    }
    getEnable();
  },[])

  const onEditMember = async (values: void) => {
    try {
      setLoading(true);
      const response = await editMember(selectedMember?.id!, values);
      if (response?.data?.id) {
        message.success('កែប្រែសមាជិកជោគជ័យ');
        setSelectedMember(response?.data);
        setDrawerStatus(DrawerStatus.SEARCH_DETAIL);
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onSave = async () => {
    form
      .validateFields()
      .then(values => {
        if (drawerStatus === DrawerStatus.EDIT_MEMBER) {
          onEditMember(values);
          return;
        }
      })
      .catch(info => {
        console.log('validate field', info);
      });
  };

  const onCancel = () => {
    form.resetFields();
    setDrawerStatus(DrawerStatus.SEARCH_DETAIL);
  };

  return (
    <div>
      <Divider style={{ fontSize: 24, fontWeight: 800 }}>{title.info}</Divider>
      <div style={{ border: '1px solid #ccc', padding: 20, marginTop: 30 }}>
        <div style={{ textAlign: 'right' }}>
          {isEnableEdit ? (
            <DrawerExtra
              loading={loading}
              onCancel={onCancel}
              onSave={onSave}
              onEditClick={() => setDrawerStatus(DrawerStatus.EDIT_MEMBER)}
            />
          ) : (
            ''
          )}
        </div>

        {drawerStatus === DrawerStatus.SEARCH_DETAIL ? (
          <MemberDetail memberInfo={selectedMember} />
        ) : (
          <MemberForm
            initialValues={selectedMember}
            form={form}
            disabled={loading}
          />
        )}
      </div>
    </div>
  );
};
