import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import { DrawerStatus, useDrawerStatus } from '../../../../store';
import { useEffect, useState } from 'react';
import { getMe } from '../../../../api';
import { permissions_string } from '../../../../components';

type Props = {
  onCancel?: () => void;
  onSave?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  loading?: boolean;
};

export const DrawerExtra = ({
  onCancel,
  onSave,
  onDeleteClick,
  onEditClick,
  loading,
}: Props) => {
  const [getme, setGetme] = useState<any>();

  useEffect(() => {
    const getProfile = async () => {
      const res = await getMe();
      setGetme(res?.data);
    };
    getProfile();
  }, []);

  const drawerStatus = useDrawerStatus();

  if (
    drawerStatus === DrawerStatus.CREATE_MEMBER ||
    drawerStatus === DrawerStatus.EDIT_MEMBER
  ) {
    return (
      <Space>
        {drawerStatus === DrawerStatus.EDIT_MEMBER && (
          <Button disabled={loading} onClick={onCancel}>
            បោះបង់
          </Button>
        )}

        <Button
          disabled={loading}
          type="primary"
          onClick={onSave}
          htmlType="submit">
          រក្សាទុក
        </Button>
      </Space>
    );
  }

  if (drawerStatus === DrawerStatus.VIEW_DETAIL) {
    return (
      <Space>
        {getme?.permission_string?.find(
          (p: any) => p === permissions_string.editMember,
        ) ? (
          <Button
            disabled={loading}
            icon={<EditOutlined />}
            onClick={onEditClick}
          />
        ) : null}

        {getme?.permission_string?.find(
          (p: any) => p === permissions_string.deleteMember,
        ) ? (
          <Popconfirm
            title="លុបទិន្នន័យរបស់សមាជិក"
            description="តើអ្នកពិតជាចង់លុបមែនទេ?"
            onConfirm={onDeleteClick}
            okText="លុប"
            cancelText="ទេ">
            <Button
              disabled={loading}
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        ) : null}
      </Space>
    );
  }

  if (drawerStatus === DrawerStatus.SEARCH_DETAIL) {
    return (
      <Button
        disabled={loading}
        icon={<EditOutlined />}
        onClick={onEditClick}
      />
    );
  }

  return <></>;
};
