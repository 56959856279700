import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Image, message, Upload } from 'antd';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { useState } from 'react';
import {
  FILE_DOWNLOAD_URL,
  FILE_UPLOAD_URL,
} from '../../../../constants/environment';
import { beforeImageUpload } from '../../../../utils';

type Props = {
  defaultImageUrl?: string;
} & UploadProps;

export const AvatarUpload = (props: Props) => {
  const [status, setStatus] = useState<UploadFileStatus>();
  const [imageUrl, setImageUrl] = useState<string | undefined>(
    () => props.defaultImageUrl,
  );

  console.log('sss', imageUrl);

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    setStatus(info.file.status);

    switch (info.file.status) {
      case 'done':
        setImageUrl(info.file.response);
        break;
      case 'error':
        setImageUrl(undefined);
        message.error('Upload image failed!');
        break;

      default:
        break;
    }
  };

  const uploadButton = (
    <div>
      {status === 'uploading' ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        {...props}
        accept=".jpg,.jpeg,.png"
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={FILE_UPLOAD_URL}
        beforeUpload={beforeImageUpload}
        onChange={info => {
          handleChange(info);
          props?.onChange && props.onChange(info);
        }}>
        {imageUrl ? (
          <Image
            preview={false}
            src={`${FILE_DOWNLOAD_URL}/${imageUrl}?dim=80x80`}
            alt="avatar"
            style={{ width: '100%' }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};
