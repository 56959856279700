import { EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Button, Form, Grid, Space, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { MemberImage, permissions_string } from '../../../components';
import {
  genderOptions,
  getLabelFromOptions,
  memberTypeOptions,
} from '../../../constants';
import { UseTableScrollSize, useTableSrollSize } from '../../../hooks';
import {
  DrawerStatus,
  useDrawerStatus,
  useSetDrawerStatus,
  useSetSelectedMember,
} from '../../../store';
import { TotalMember, getMembersTableData } from '../../../utils';
import { MemberDrawer } from './Drawer/MemberDrawer';
import { FilterForm } from './FilterForm';
import { useEffect, useState } from 'react';
import { getMe } from '../../../api';

const { useBreakpoint } = Grid;
const useTableScrollProps: UseTableScrollSize = {
  tableId: 'member-table',
  filterId: 'member-filter',
};

export const MemberListPage = () => {
  const [getme, setGetme] = useState<any>();
  const [form] = Form.useForm();
  const setDrawerStatus = useSetDrawerStatus();
  const setSelectedMember = useSetSelectedMember();
  const status = useDrawerStatus();

  const scrollSize = useTableSrollSize(useTableScrollProps);

  const { xs } = useBreakpoint();

  useEffect(() => {
    const getProfile = async () => {
      const res = await getMe();
      setGetme(res?.data);
    };
    getProfile();
  }, []);

  const {
    tableProps,
    search: { submit },
    refresh,
  } = useAntdTable(getMembersTableData, {
    defaultPageSize: 10,
    form,
  });

  const columns: ColumnProps<any>[] = [
    {
      title: 'រូបថត',
      dataIndex: 'photo',
      key: 'photo',
      render: value => {
        const size = 60;
        return <MemberImage fileName={value} photoSize={size} />;
      },
      responsive: ['sm'],
    },
    {
      title: 'ឈ្មោះ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ភេទ',
      dataIndex: 'gender',
      key: 'gender',
      render: value => getLabelFromOptions(genderOptions, value),
    },
    {
      title: 'ប្រភេទសមាជិក',
      dataIndex: 'memberType',
      key: 'memberType',
      sorter: {
        compare: (a, b) => {
          return a.memberType.trim().localeCompare(b.memberType.trim());
        },
      },
      render: value => getLabelFromOptions(memberTypeOptions, value),
    },
    {
      title: 'លេខទូរសព្ទ',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: record => {
        return (
          <Space direction="horizontal">
            <Button
              onClick={() => {
                setSelectedMember({ ...record });
                setDrawerStatus(DrawerStatus.VIEW_DETAIL);
              }}
              icon={<EyeOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div id={useTableScrollProps.filterId}>
        <FilterForm
          memberType={'AllMember'}
          form={form}
          onSubmit={submit}
          extra={
            getme?.permission_string?.find(
              (p: any) => p === permissions_string.createMember,
            ) ? (
              <Button
                block={xs}
                size="large"
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setSelectedMember(null);
                  setDrawerStatus(DrawerStatus.CREATE_MEMBER);
                }}>
                បង្កើតថ្មី
              </Button>
            ) : null
          }
        />
      </div>
      <Table
        id={useTableScrollProps.tableId}
        rowKey="id"
        columns={columns}
        size="large"
        scroll={{
          y: scrollSize.y,
        }}
        {...tableProps}
      />
      <TotalMember total={tableProps.pagination.total} />

      {status !== DrawerStatus.CLOSE && <MemberDrawer refetch={refresh} />}
    </>
  );
};
