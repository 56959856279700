import { ConfigProvider, Layout } from 'antd';
import { primaryColor } from '../constants';
import { SiteLayout } from './SiteLayout';

const MainLayout = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primaryColor,
          fontFamily: 'Kantumruy',
          // colorBgLayout: '#F5F5F5',
        },
        components: {
          Menu: {
            colorItemTextHover: primaryColor,
          },
        },
      }}>
      <Layout>
        <SiteLayout />
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;
