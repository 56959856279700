import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, ColProps, Form, FormInstance, Input, Row, Select } from 'antd';
import { memberTypeOptions } from '../../../constants';

type Props = {
  extra?: React.ReactNode;
  form: FormInstance<any>;
  onSubmit: () => void;
  extraColProps?: ColProps;
  memberType: any;
};

const { Option } = Select;

memberTypeOptions.push({label: 'ទាំងអស់', value: ''})

export const FilterForm = (props: Props) => {
  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        gutter={[15, 15]}
        style={{ padding: '1rem 0' }}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} {...props.extraColProps}>
          {props.extra}
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form name="filterForm" colon={false} form={props.form} size="large">
            <Row gutter={5}>
              <Col span={5}>
                {props.memberType === 'AllMember' ? 
                <Form.Item
                  name="memberType"
                  initialValue=""
                  style={{ margin: 0 }}>
                  <Select onChange={props.onSubmit} options={memberTypeOptions}></Select>
                </Form.Item>
                : ''
                }
              </Col>
              <Col span={8}>
                <Form.Item name="name" noStyle>
                  <Input placeholder="ស្វែងរកឈ្មោះ" allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="phoneNumber" noStyle>
                  <Input placeholder="ស្វែងរកលេខទូរសព្ទ" allowClear />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item noStyle>
                  <Button
                    style={{ width: '100%' }}
                    htmlType="submit"
                    type="default"
                    onClick={props.onSubmit}
                    icon={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
