import { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadProps, message } from 'antd';
import { FILE_DOWNLOAD_URL, FILE_UPLOAD_URL } from '../../../../constants';
import { beforeImageUpload } from '../../../../utils';

type Props = {
  defaultFileUrl?: string[];
} & UploadProps;

export const FileUpload = (props: Props) => {
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (props?.defaultFileUrl)
      setFileList(generateFileList(props?.defaultFileUrl));
  }, []);

  const generateFileList = (attachments: string[]) => {
    return attachments.map((attachment, index) => ({
      uid: index.toString(),
      name: attachment,
      url: `${FILE_DOWNLOAD_URL}/${attachment}`,
      size: 0,
      type: 'image/jpg',
    }));
  };

  const getUrlFromFile = (file: any) => {
    const prefix = `${FILE_DOWNLOAD_URL}`;
    return `${prefix}${file.response}`;
  };

  const handleFileListChange = ({ fileList }: any) => {
    if (fileList.length > 5) {
      return message.error('File upload has reached to 5 maximum');
    }

    const tmpList = fileList.map((file: any) => {
      return file.response ? { ...file, url: getUrlFromFile(file) } : file;
    });
    setFileList(tmpList);
  };

  return (
    <Dragger
      listType="picture"
      onChange={info => {
        handleFileListChange(info);
        props?.onChange && props.onChange(info);
      }}
      action={`${FILE_UPLOAD_URL}`}
      accept=".jpg,.jpeg,.png"
      beforeUpload={beforeImageUpload}
      multiple={true}
      // maxCount={5}
      fileList={[...fileList]}
      showUploadList={{
        downloadIcon: true,
        removeIcon: false,
        showPreviewIcon: true,
      }}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  );
};
