import { Image, ImageProps } from 'antd';
import { FILE_DOWNLOAD_URL } from '../../constants';
import { avatar_fallback } from '../../images';

type Props = {
  fileName?: string;
  photoSize?: number;
} & Omit<ImageProps, 'src'>;

export const MemberImage = ({ fileName, photoSize = 80, ...props }: Props) => {
  if (!fileName) {
    return (
      <Image
        {...props}
        src={avatar_fallback}
        preview={{
          src: avatar_fallback,
        }}
        fallback={avatar_fallback}
        style={{ borderRadius: 5, width: photoSize }}
      />
    );
  }

  const photoUrl = `${FILE_DOWNLOAD_URL}/${fileName}`;

  return (
    <Image
      {...props}
      src={`${photoUrl}?dim=${photoSize}x${photoSize}`}
      preview={{
        src: photoUrl,
      }}
      fallback={avatar_fallback}
      style={{ borderRadius: 5, width: photoSize }}
    />
  );
};
