import { Layout } from 'antd';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserToken } from '../store';

export const SiteLayout = () => {
  const location = useLocation();
  const token = useUserToken();

  if (location.pathname === '/') {
    const pathname = token ? 'admin' : 'search';
    return <Navigate to={pathname} />;
  }

  if (!token && location.pathname.startsWith('/admin')) {
    return <Navigate to="login" />;
  }

  if (location.pathname.startsWith('/self-register')) {
    return <Navigate to="login" />;
  }

  return (
    <Layout className="site-layout">
      <Outlet />
    </Layout>
  );
};
