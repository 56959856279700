import { useSize } from 'ahooks';

export type UseTableScrollSize = {
  tableId: string;
  filterId: string;
};

export const useTableSrollSize = ({
  tableId,
  filterId,
}: UseTableScrollSize) => {
  const navBarSize = useSize(
    document.querySelector(`${tableId} .ant-layout-header`),
  );

  const paginationSize = useSize(
    document.querySelector(`${tableId} .ant-pagination`),
  );

  const tableHeaderSize = useSize(
    document.querySelector(`${tableId} .ant-table-header`),
  );

  const filterSize = useSize(document.querySelector(filterId));

  let substractSize = '19rem';

  if (tableHeaderSize && filterSize && navBarSize && paginationSize) {
    substractSize =
      tableHeaderSize.height +
      filterSize.height +
      navBarSize?.height +
      paginationSize.height +
      +40 +
      'px';
  }

  return { y: `calc(100vh - ${substractSize})` };
};
