import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../../components';
import { useLogin, useUserToken } from '../../../store';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useUserToken();
  const login = useLogin();

  const onFinish = async (data: any) => {
    try {
      setLoading(true);
      await login(data);
    } catch (error) {
      console.log('login component : ', error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (token) {
      navigate('/admin', { replace: true });
    }
  }, [token, navigate]);

  return (
    <Space
      wrap
      size={'large'}
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Logo />
      <Form
        size="large"
        name="login"
        layout="vertical"
        scrollToFirstError
        autoComplete="off"
        initialValues={{ remember: true }}
        disabled={loading}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input
            autoComplete="username"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="ឈ្មោះអ្នកប្រើប្រាស់"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password
            autoComplete="current-password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="លេខសម្ងាត់"
          />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}>
            ចូលប្រើប្រាស់
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};

// export default AuthenticationPage;
