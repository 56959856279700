
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Font,
  Image,
} from '@react-pdf/renderer';
import { genderOptions, getLabelFromOptions, memberTypeOptions } from '../../constants';
import logo from '../../images/UYFC.png'
import KantumruyProBold from '../../fonts/KantumruyPro-Bold.ttf';
import { label, title } from '../../constants/title';

Font.register({
  family: 'KantumruyBold',
  src: KantumruyProBold,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontFamily: 'KantumruyBold',
    fontSize: 20,
    marginBottom: 20,
    marginTop: 20,
  },
  title: {
    fontFamily: 'KantumruyBold',
    fontSize: 24,
    marginBottom: 20,
  },
  subtitle: {
    fontFamily: 'KantumruyBold',
    fontSize: 16,
    marginBottom: 15,
  },
  text: {
    fontFamily: 'KantumruyBold',
    color: 'darkblue',
    fontSize: 16,
  },
  greyTitle: {
    fontFamily: 'KantumruyBold',
    color: 'rgba(0,0,0,0.5)',
    fontSize: 14,
    marginBottom: 20,
    marginTop: -8,
  },
  image: {
    width: 180,
    height: 180,
  },
  logo: {
    width: 100,
    height: 100
  }
});

export const PDFMemberInfo = ({memberInfo}) => {
  let url;
  const canvas = document.getElementById('memberQr').querySelector('canvas');
  
  if (canvas) {
    url = canvas.toDataURL();
  }
  
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.greyTitle}>{title.event} </Text>
          <Text style={styles.title}>{title.info} </Text>
          <Image src={url} style={styles.image} />
          <Text style={styles.name}>
            {label.name}៖  <Text style={{ color: 'darkblue' }}>{memberInfo?.name} </Text>
          </Text>
          <Text style={styles.subtitle}>
            {label.gender}៖  <Text style={styles.text}>{getLabelFromOptions(genderOptions, memberInfo?.gender)} </Text>
          </Text>
          <Text style={styles.subtitle}>
            {label.phone}៖  <Text style={styles.text}>{memberInfo?.phoneNumber} </Text>
          </Text>
          <Text style={styles.subtitle}>
            {label.memberType}៖  <Text style={styles.text}>{getLabelFromOptions(memberTypeOptions, memberInfo?.memberType)} </Text>
          </Text>
        </View>
      </Page>
    </Document>
  );
};
