import { DefaultOptionType } from 'antd/es/select';
import { MemberType } from '../types/IMember';

export const memberTypeOptions: DefaultOptionType[] = [
  { label: 'និស្សិត', value: MemberType.Student },
  { label: 'ប្រជាពលរដ្ឋក្រីក្រ', value: MemberType.Poor_Citizen },
];

export const genderOptions: DefaultOptionType[] = [
  { label: 'ប្រុស', value: 'M' },
  { label: 'ស្រី', value: 'F' },
];

export const yearOfStudyOptions: DefaultOptionType[] = [
  { label: 'ឆ្នាំទី១', value: '1' },
  { label: 'ឆ្នាំទី២', value: '2' },
  { label: 'ឆ្នាំទី៣', value: '3' },
  { label: 'ឆ្នាំទី៤', value: '4' },
  { label: 'ឆ្នាំទី៥', value: '5' },
];

export const getLabelFromOptions = (
  options: DefaultOptionType[],
  value: any,
) => {
  if (value === undefined || value === null) return 'N/A';
  return options?.find(item => item.value === value)?.label || value;
};
