import { Button, Divider, message } from 'antd';
import { useState } from 'react';
import OTPInput from 'react-otp-input';

interface Props {
  verifyMemberCode: (code: any) => void
  loadings: boolean
}

export const VerifyCodeBox = (props: Props) => {
  const { verifyMemberCode, loadings } = props;
  const [code, setCode] = useState('')
  const [isError, setIsError] = useState(false)
  
  const handleInputChange = (e: any) => {
   setCode(e)
  };

  const handleSubmitCode = () => {

    if(!code) {
      setIsError(true)
      return message.error('សូមបញ្ចូលលេខកូដ៤ខ្ទង់')
    }
    else if (code.length !== 4) {
      setIsError(true)
      return message.error('សូមបញ្ចូលលេខកូដ៤ខ្ទង់')
    }

    verifyMemberCode(code);
   
  }

  const renderInput = (inputProp: any, index: any) => {
    return (
      <input
        {...inputProp}
        key={index}
        style={{
          width: "3rem",
          height: "3rem",
          margin: "1.5rem 0.7rem",
          fontSize: "2rem",
          borderRadius: 4,
          border: `${isError ? "1px solid red" : "1px solid rgba(0,0,0,0.3)"}`,
          textAlign: "center",
          outline: "none",
        }}
      />
    );
  };
  
  return (
    <div style={{textAlign: 'center'}}>
      <Divider>បញ្ចូលលេខកូដ៤ខ្ទង់</Divider>
      
      <div style={{width: 280, margin: '20px auto'}}>
         <OTPInput 
          value={code}
          onChange={handleInputChange}
          numInputs={4} 
          renderInput={renderInput}
          shouldAutoFocus
        />
      </div>
       
      <Button 
        type='primary' 
        size='large' 
        style={{width: 280}} 
        onClick={handleSubmitCode}
        loading={loadings}
      >
        Verify Code
      </Button>
    </div>
    
  );
};
