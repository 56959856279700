import { Button, Col, Form, message, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { useState } from 'react';
import { addMember } from '../../api';
import { ICommon } from '../../types/ICommon';
import { IMember } from '../../types/IMember';
import { MemberForm } from '../Admin/MemberList/Form/MemberForm';
import { SuccessPage } from './SuccessPage';

export const MemberPage = () => {
  const [status, setStatus] = useState<UploadFileStatus>();
  const [memberInfo, setMemberInfo] =
    useState<ICommon.MaybeNull<IMember.Info>>(null);
  const [form] = Form.useForm();

  const onAddMember = async (values: any) => {
    try {
      setStatus('uploading');
      const response = await addMember(values);
      if (response?.data?.id) {
        setMemberInfo(response?.data);
        setStatus('success');
      }
    } catch (error) {
      setStatus('error');
      let err = error as any;
      message.error(err?.response?.data?.message);
      console.log('add-member', error);
    }
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(values => {
        onAddMember(values);
        // form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const onReSignup = () => {
    setStatus(undefined);
    setMemberInfo(null);
    form.resetFields();
  };

  if (status === 'success') {
    return <SuccessPage memberInfo={memberInfo} onReSignup={onReSignup} />;
  }

  return (
    <Content style={{ margin: '0px 1rem' }}>
      <Row style={{ padding: '1rem 0px' }} justify={'center'}>
        <Col xs={24} sm={16}>
          <Typography.Title level={2}>
            ចុះឈ្មោះសមាជិកគ្រួសារយើង
          </Typography.Title>
          <MemberForm form={form} disabled={status === 'uploading'} />
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            form="memberForm"
            loading={status === 'uploading'}
            onClick={onSubmit}>
            ចុះឈ្មោះ
          </Button>
        </Col>
      </Row>
    </Content>
  );
};
