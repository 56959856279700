import React, { useState } from 'react'
import { Button, Col, Divider, Drawer, Grid, Image, message, QRCode, Row } from 'antd'
import Search from 'antd/es/input/Search'
import { Content } from 'antd/es/layout/layout'
import { getMemberByPhone, verifyCode } from './../../api/index';
import { FILE_DOWNLOAD_URL, genderOptions, getLabelFromOptions, QR_PREFIX } from '../../constants';
import { downloadQRCode } from '../../utils';
import { ICommon } from '../../types/ICommon';
import { IMember } from '../../types/IMember';
import { avatar_fallback } from './../../images/index';
import { VerifyCodeBox } from './VerifyCodeBox';
import uyfc_logo from "../../images/UYFC.png"
import { MemberInfo } from './MemberInfo';
import { DrawerStatus, useSetDrawerStatus } from '../../store';
import { useNavigate } from 'react-router-dom';
import { LoginOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

export const SearchPage = () => {
  const [loadings, setLoadings] = useState(false)
  const [phone, setPhone] = useState('')
  const [memberInfo, setMemberInfo] = useState<ICommon.MaybeNull<IMember.Info>>(null);
  const [showMemberInfo, setShowMemberInfo] = useState(false)
  const [showBox, setShowBox] = useState(false)
  const setDrawerStatus = useSetDrawerStatus()
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const getWidth = () => {
    if (screens.xxl) return 1200;
    if (screens.xl) return 960;
    if (screens.lg) return 720;
    return '100%';
  };

  const onSearch = async() => {

    if(!phone) {
      return message.error("សូមបញ្ចូលលេខទូរសព្ទ")
    }

    try {
      setLoadings(true)
      const response = await getMemberByPhone(phone)
     
      if(response?.data) {
        // message.success('បានផ្ញើលេខកូដរួចរាល់')
        setShowBox(true)
      }
        
    } catch (error) {
      message.error('លេខទូរសព្ទមិនមានក្នុងប្រព័ន្ធ')
    }
    setLoadings(false)
  }

  const verifyMemberCode = async (code: any) => {
    setLoadings(true)
    const number = phone
    try {
      const response = await verifyCode(code, number)

      if(!response?.data) {
        setLoadings(false)
        return message.error("លេខកូដមិនត្រឹមត្រូវ")
      } 
      setShowMemberInfo(true)
      setMemberInfo(response?.data)
      setDrawerStatus(DrawerStatus.SEARCH_DETAIL)
      setLoadings(false)
    } catch (error) {
      message.error('error')
    }  
  }

  return (
    <>
      <div style={{ textAlign: 'right', padding: '20px 20px 0 0' }}>
        <Button type="primary" onClick={() => navigate('/login')}>
          <LoginOutlined /> ចូលគណនី
        </Button>
      </div>

      <Content
        style={{
          width: getWidth(),
          height: '100vh',
          margin: '20px auto',
          padding: 20,
        }}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src={uyfc_logo} alt="uyfc_logo" width={80} height={80} />
        </div>

        {showMemberInfo ? (
          <MemberInfo memberInfo={memberInfo} />
        ) : showBox ? (
          <VerifyCodeBox
            verifyMemberCode={verifyMemberCode}
            loadings={loadings}
          />
        ) : (
          <div>
            <Divider>ស្វែងរកតាមរយៈលេខទូរសព្ទ</Divider>
            <Search
              placeholder="សូមបញ្ចូលលេខទូរសព្ទ"
              enterButton
              size="large"
              loading={loadings}
              onChange={e => setPhone(e.target.value)}
              onSearch={onSearch}
            />
          </div> 
        )}
      </Content>
    </>
  );
}
