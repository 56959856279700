import { Button, QRCode, Result } from 'antd';
import { QR_PREFIX } from '../../constants';
import { ICommon } from '../../types/ICommon';
import { IMember } from '../../types/IMember';
import { downloadQRCode } from '../../utils';

type Props = {
  onReSignup: () => void;
  memberInfo: ICommon.MaybeNull<IMember.Info>;
};

export const SuccessPage = (props: Props) => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Result
        status="success"
        title="ការចុះឈ្មោះជោគជ័យ"
        subTitle="សូមទាញយក QR code ខាងក្រោមសម្រាប់ចុះវត្តមាន"
        extra={
          <>
            <div
              key="memberQr"
              id="memberQr"
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '1rem 0 2rem 0',
              }}>
              <QRCode value={`${QR_PREFIX}${props.memberInfo?.id}`} />
            </div>
            <Button
              key="downloadQr"
              size="large"
              type="primary"
              onClick={() => downloadQRCode(props.memberInfo?.name)}>
              ទាញយក
            </Button>
            <Button key="signup" size="large" onClick={props.onReSignup}>
              ចុះឈ្មោះថ្មី
            </Button>{' '}
          </>
        }
      />
    </div>
  );
};
