import { IUser } from '../types/IUser';
import { request } from '../utils';

export const addMember = async (body: any) => {
  const response = await request.post(`/api/members`, body);
  return response?.data;
};

export const editMember = async (id: string, body: any) => {
  const response = await request.patch(`/api/members/${id}`, body);
  return response?.data;
};

export const deleteMember = async (id: string) => {
  const response = await request.delete(`/api/members/${id}`);
  return response?.data;
};

export const getMembers = async (queryString = '', includeAttend = true) => {
  const response = await request.get(
    `/api/members?${queryString}&includeAttend=${includeAttend}`,
  );
  return response?.data;
};

export const getStudentMembers = async (
  queryString = '',
  includeAttend = true,
) => {
  const response = await request.get(
    `/api/members?${queryString}&includeAttend=${includeAttend}&memberType=Student`,
  );
  return response?.data;
};

export const getPoorMembers = async (
  queryString = '',
  includeAttend = true,
) => {
  const response = await request.get(
    `/api/members?${queryString}&includeAttend=${includeAttend}&memberType=Poor_Citizen`,
  );
  return response?.data;
};

export const getMemberById = async (id: string) => {
  const response = await request.get(`/api/members/${id}`);
  return response?.data;
};

export const getMemberByPhone = async (phone: string) => {
  const response = await request.get(`/api/members/phone/${phone}`);
  return response?.data;
};

export const getScanCount = async (
  monthYearString: string,
  params: any = {},
) => {
  const response = await request.get(
    `/api/attendances/scan/${monthYearString}`,
    { params },
  );
  return response?.data;
};

export const login = async (body: IUser.LoginBody) => {
  const response = await request.post(`/api/authentication/login`, body);
  return response?.data;
};

export const validateToken = async (token?: string) => {
  const response = await request.post(`/api/authentication/validate-token`, {
    token,
  });
  return response?.data;
};

export const verifyCode = async (code: any, number: any) => {
  const response = await request.post(`/api/members/code-verification`, {
    code,
    number,
  });
  return response?.data;
};

export const getEnableStudentEdit = async () => {
  const response = await request.get(`/api/setting?key=enableStudentEdit`);
  return response?.data;
};

export const changeEnablesStudentEdit = async (value: string) => {
  const response = await request.patch(
    `/api/setting?key=enableStudentEdit&value=${value}`,
  );
  return response?.data;
};

export const getUsers = async () => {
  const response = await request.get(`/api/users`);
  return response?.data;
};

export const addUser = async (body: any) => {
  const response = await request.post(`/api/users`, body);
  return response?.data;
};

export const deleteUser = async (id: string) => {
  const response = await request.delete(`/api/users/${id}`);
  return response?.data;
};

export const editUser = async (id: string, body: any) => {
  const response = await request.patch(`/api/users/${id}`, body);
  return response?.data;
};

export const getRole = async () => {
  const response = await request.get(`/api/roles`);
  return response?.data;
};

export const getMe = async () => {
  const response = await request.get(`/api/users/me`);
  return response?.data;
};

export const registerAttendance = async (member: string) => {
  const response = await request.post(`api/attendances`, { member });
  return response?.data;
};
