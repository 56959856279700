import React from "react";

type Props = {
  total: number
}

export const TotalMember = (props: Props) => {
  return (
    <div
      style={{
        fontSize: 16,
        color: 'rgba(0,0,0,0.5)',
        textAlign: 'center',
      }}>
      {`ចំនួនសមាជិក៖ ${props?.total} នាក់`}
    </div>
  );
};