import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

type Props = {
  onDeleteClick: () => void
  loading: boolean
  title: any
}

export const PopConfirmDelete = (props: Props) => {
  return (
    <Popconfirm
      title={`លុបទិន្នន័យ${props.title}`}
      description="តើអ្នកពិតជាចង់លុបមែនទេ?"
      onConfirm={props.onDeleteClick}
      okText="លុប"
      cancelText="ទេ">
      <Button
        disabled={props.loading}
        type="primary"
        danger
        icon={<DeleteOutlined />}
      />
    </Popconfirm>
  );
}