import { Drawer, DrawerProps, Form, Grid, message } from 'antd';
import { useState } from 'react';
import { addMember, deleteMember, editMember } from '../../../../api';
import {
  DrawerStatus,
  useDrawerStatus,
  useSelectedMember,
  useSetDrawerStatus,
  useSetSelectedMember,
} from '../../../../store';
import { MemberForm } from '../Form/MemberForm';
import { DrawerExtra } from './DrawerExtra';
import { MemberDetail } from './MemberDetail';

type Props = {
  refetch: () => void;
} & DrawerProps;

const { useBreakpoint } = Grid;

const getTitle = (status: DrawerStatus) => {
  return status === DrawerStatus.EDIT_MEMBER
    ? 'កែប្រែព័ត៌មានសមាជិក'
    : status === DrawerStatus.CREATE_MEMBER
    ? 'ចុះឈ្មោះជនងាយរងគ្រោះ'
    : 'ព័ត៌មានសមាជិក';
};

export const MemberDrawer = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const selectedMember = useSelectedMember();
  const drawerStatus = useDrawerStatus();
  const setDrawerStatus = useSetDrawerStatus();
  const setSelectedMember = useSetSelectedMember();
  const screens = useBreakpoint();

  const onSaveSuccess = (data: any, msg?: string) => {
    msg && message.success(msg);
    props.refetch();
    setSelectedMember(data);
    setDrawerStatus(DrawerStatus.VIEW_DETAIL);
  };

  const onDrawerClose = () => {
    form.resetFields();
    setSelectedMember(null);
    setDrawerStatus(DrawerStatus.CLOSE);
  };

  const onAddMember = async (values: any) => {
    try {
      setLoading(true);
      const response = await addMember(values);
      if (response?.data?.id) {
        onSaveSuccess(response.data, 'បង្កើតសមាជិកជោគជ័យ');
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
      console.log('add-member', error);
    } finally {
      setLoading(false);
    }
  };

  const onEditMember = async (values: any) => {
    try {
      setLoading(true);
      const response = await editMember(selectedMember?.id!, values);
      if (response?.data?.id) {
        onSaveSuccess(response?.data, 'កែប្រែសមាជិកជោគជ័យ');
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
      console.log('add-member', error);
    } finally {
      setLoading(false);
    }
  };

  const onDeletMember = async (id: string) => {
    try {
      setLoading(true);
      const response = await deleteMember(id);
      if (response?.data?.affected) {
        props.refetch();
        onDrawerClose();
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
      console.log('delete-member', error);
    } finally {
      setLoading(false);
    }
  };

  const onSave = async () => {
    form
      .validateFields()
      .then(values => {
        if (drawerStatus === DrawerStatus.CREATE_MEMBER) {
          onAddMember(values);
          return;
        }

        if (drawerStatus === DrawerStatus.EDIT_MEMBER) {
          onEditMember(values);
          return;
        }
        // form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const onCancel = () => {
    form.resetFields();
    setDrawerStatus(DrawerStatus.VIEW_DETAIL);
  };

  if (drawerStatus === DrawerStatus.CLOSE) return <></>;

  return (
    <>
      <Drawer
        open={true}
        onClose={onDrawerClose}
        title={getTitle(drawerStatus)}
        size="large"
        placement={
          (screens.sm || screens.xs) && !screens.md ? 'bottom' : 'left'
        }
        height="80vh"
        extra={
          <DrawerExtra
            loading={loading}
            onCancel={onCancel}
            onSave={onSave}
            onEditClick={() => setDrawerStatus(DrawerStatus.EDIT_MEMBER)}
            onDeleteClick={() => onDeletMember(selectedMember?.id!)}
          />
        }
        {...props}>
        {drawerStatus === DrawerStatus.VIEW_DETAIL ? (
          <MemberDetail memberInfo={selectedMember} />
        ) : (
          <MemberForm
            disabled={loading}
            form={form}
            initialValues={selectedMember}
          />
        )}
      </Drawer>
    </>
  );
};
