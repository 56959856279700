import { message } from 'antd';
import { create } from 'zustand';
import { addMember } from '../api';
import { ICommon } from '../types/ICommon';
import { IMember } from '../types/IMember';

export enum DrawerStatus {
  VIEW_DETAIL = 'VIEW_DETAIL',
  CREATE_MEMBER = 'CREATE_MEMBER',
  EDIT_MEMBER = 'EDIT_MEMBER',
  CLOSE = 'CLOSE',
  SEARCH_DETAIL = 'SEARCH_DETAIL'
}

interface MemberListState {
  drawerStatus: DrawerStatus;
  selectedMember: ICommon.MaybeNull<IMember.Info>;
  setDrawerStatus: (drawerStatus: DrawerStatus) => void;
  setSelectedMember: (selectedMember: ICommon.MaybeNull<IMember.Info>) => void;
  createMember: (info: IMember.Info) => Promise<any>;
}

export const memberDrawerStore = create<MemberListState>()(set => ({
  drawerStatus: DrawerStatus.CLOSE,
  selectedMember: null,
  setDrawerStatus: drawerStatus => {
    set({ drawerStatus });
  },
  setSelectedMember: selectedMember => {
    set({ selectedMember });
  },
  createMember: async info => {
    try {
      const response = await addMember(info);

      if (response?.data?.id) {
        set({
          drawerStatus: DrawerStatus.VIEW_DETAIL,
          selectedMember: response.data,
        });
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
      console.log('add-member', error);
    }
  },
}));

export const useDrawerStatus = () =>
  memberDrawerStore(({ drawerStatus }) => drawerStatus);
export const useSelectedMember = () =>
  memberDrawerStore(({ selectedMember }) => selectedMember);
export const useSetDrawerStatus = () =>
  memberDrawerStore(({ setDrawerStatus }) => setDrawerStatus);
export const useSetSelectedMember = () =>
  memberDrawerStore(({ setSelectedMember }) => setSelectedMember);
export const useCreateMember = () =>
  memberDrawerStore(({ createMember }) => createMember);
