import { useAntdTable } from 'ahooks';
import {
  Table,
  DatePicker,
  Form,
  Grid,
  Button,
  Popconfirm,
  message,
  Modal,
} from 'antd';
import { ColumnProps } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { IMember, MemberType } from '../../../types/IMember';
import {
  TotalMember,
  formattedDate,
  getMembersTableData,
  getPoorMembersTableData,
  getStudentMembersTableData,
} from '../../../utils';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import 'dayjs/locale/km';
import kmLocale from 'antd/es/date-picker/locale/km_KH';
import { FilterForm } from '../MemberList/FilterForm';
import { UseTableScrollSize, useTableSrollSize } from '../../../hooks';
import { deleteMember, getMe, getScanCount } from '../../../api';
import { permissions_string } from '../../../components';
const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const { useBreakpoint } = Grid;
const useTableScrollProps: UseTableScrollSize = {
  tableId: 'attendance-table',
  filterId: 'attendance-filter',
};

const getMonthList = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  let result: any = [];

  const duration = endDate.diff(startDate, 'month');

  for (let index = 0; index <= duration; index++) {
    result.push(startDate.add(index, 'month'));
  }
  return result;
};

type Props = {
  memberType: MemberType;
};

export const AttendanceListPage = ({ memberType }: Props) => {
  const [form] = Form.useForm();
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>([
    dayjs().subtract(5, 'month'),
    dayjs(),
  ]);
  const [getme, setGetme] = useState<any>();

  useEffect(() => {
    const getProfile = async () => {
      const res = await getMe();
      setGetme(res?.data);
    };
    getProfile();
  }, []);

  const scrollSize = useTableSrollSize(useTableScrollProps);
  const { xs } = useBreakpoint();

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 4;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') > 4;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onDeleteClick = async (id: string) => {
    try {
      const response = await deleteMember(id);
      if (response?.data?.affected) {
        refresh();
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
    }
  };

  const onInfoClick = async (monthYearString: any) => {
    try {
      const response = await getScanCount(monthYearString, { memberType });
      if (!response) {
        return message.error('error');
      }
      Modal.info({
        title: (
          <span style={{ fontFamily: 'Kantumruy' }}>
            {monthYearString.locale('km').format('MMMM-YYYY')}
          </span>
        ),
        content: (
          <span style={{ fontFamily: 'Kantumruy' }}>
            {response?.data
              ? `សមាជិកដែលបានចុះវត្តមានសរុបចំនួន ${response?.data} នាក់`
              : `មិនមានសមាជិកចុះវត្តមានទេ`}
          </span>
        ),
        onOk() {},
      });
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
    }
  };

  const {
    tableProps,
    search: { submit },
    refresh,
  } = useAntdTable(
    memberType === MemberType.Student
      ? getStudentMembersTableData
      : getPoorMembersTableData,
    {
      defaultPageSize: 10,
      defaultCurrent: 2,
      form,
    },
  );

  let columns: ColumnProps<IMember.AttendInfo>[] = [
    {
      title: 'ឈ្មោះ',
      dataIndex: 'name',
      key: 'name',
      width: '10rem',
      fixed: true,
    },
  ];

  let columnDel: ColumnProps<IMember.Info>[] = getme?.permission_string?.find(
    (p: any) => p === permissions_string.deleteMember,
  )
    ? [
        {
          title: 'លុប',
          key: 'action',
          width: '10rem',
          align: 'center',
          render: (record: any) => {
            return (
              <Popconfirm
                title="លុបទិន្នន័យរបស់សមាជិក"
                description="តើអ្នកពិតជាចង់លុបមែនទេ?"
                onConfirm={() => onDeleteClick(record?.id)}
                okText="លុប"
                cancelText="ទេ">
                <Button
                  // disabled={loading}
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            );
          },
        },
      ]
    : [];

  if (value?.[0] && value[1]) {
    const list = getMonthList(value?.[0], value[1])?.map((month: any) => ({
      title: (
        <span>
          {month.locale('km').format('MMMM-YYYY')}{' '}
          <InfoCircleOutlined onClick={() => onInfoClick(month)} />
        </span>
      ),
      align: 'center',
      dataIndex: 'attendances',
      key: month.format('MMMM'),
      width: 150,
      render: (value: IMember.AttendInfo['attendances'], record: any) => {
        const isPresent = value?.find(v => {
          return (
            month.format('MMMM') === dayjs(v.createdAt).format('MMMM') &&
            month.format('YYYY') === dayjs(v.createdAt).format('YYYY')
          );
        });

        const newformattedDate = formattedDate(isPresent?.createdAt);

        return !!isPresent ? (
          <div>
            <CheckOutlined style={{ color: 'green' }} />
            <span style={{ fontSize: 11 }}> {newformattedDate}</span>
          </div>
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        );
      },
    }));

    columns = [...columns, ...list, ...columnDel];
  }

  return (
    <>
      <div id={useTableScrollProps.filterId}>
        <FilterForm
          memberType={MemberType}
          form={form}
          onSubmit={submit}
          extra={
            <div>
              <RangePicker
                locale={kmLocale}
                picker="month"
                value={dates || value}
                disabledDate={disabledDate}
                onCalendarChange={val => setDates(val)}
                onChange={val => setValue(val)}
                onOpenChange={onOpenChange}
                format="MMMM-YYYY"
                size="large"
                style={{
                  fontFamily: 'Kantumruy',
                  width: xs ? '100%' : undefined,
                }}
              />
            </div>
          }
        />
      </div>

      <Table
        id={useTableScrollProps.tableId}
        rowKey="id"
        columns={columns}
        size="large"
        {...tableProps}
        scroll={{ y: scrollSize.y }}
      />
      <TotalMember total={tableProps.pagination.total} />
    </>
  );
};
