import { Button, Form, Grid, Modal, Result, message } from 'antd';
import { UseTableScrollSize } from '../../../hooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { TotalMember, getUsersTableData, request } from '../../../utils';
import { useEffect, useState } from 'react';
import { UserAdd } from './Add/UserAdd';
import { UserTableList } from './UserTableList';
import { permissions, permissions_string } from '../../../components';
import { getMe } from '../../../api';

export const useTableScrollProps: UseTableScrollSize = {
  tableId: 'user-table',
  filterId: 'user-filter',
};

const { useBreakpoint } = Grid;

export const UsersListPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getme, setGetme] = useState<any>();
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();

  useEffect(() => {
    const getProfile = async () => {
      const res = await getMe();
      setGetme(res?.data);
    };
    getProfile();
  }, []);

  const {
    tableProps,
    search: { submit },
    refresh,
  } = useAntdTable(getUsersTableData, {
    defaultPageSize: 10,
    form,
  });

  const onSaveFinished = (msg: string) => {
    msg && message.success(msg);
    setIsModalOpen(false);
    refresh();
  };

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  if (!permissions.userPage.includes(getme?.roles?.[0]?.name)) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <>
      <div style={{ padding: '1rem 0' }}>
        {getme?.permission_string?.find(
          (p: any) => p === permissions_string.createUser,
        ) ? (
          <Button
            block={xs}
            size="large"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => setIsModalOpen(true)}>
            បង្កើតថ្មី
          </Button>
        ) : null}
      </div>
      <UserTableList tableProps={tableProps} refresh={refresh} getme={getme} />

      {isModalOpen && (
        <Modal
          title="បង្កើតអ្នកប្រើប្រាស់ថ្មី"
          open={isModalOpen}
          onCancel={onCancel}
          footer={null}>
          <UserAdd onSaveFinished={onSaveFinished} form={form} />
        </Modal>
      )}
    </>
  );
};
