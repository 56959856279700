import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import { FC } from 'react';
import {
  genderOptions,
  memberTypeOptions,
  yearOfStudyOptions,
} from '../../../../constants';
import { MemberType } from '../../../../types/IMember';
import { AvatarUpload } from './AvatarUpload';
import { FileUpload } from './FileUpload';

type Props = {
  form?: FormInstance<any>;
  disabled?: boolean;
  initialValues?: any;
  onSubmit?: (e: any) => void;
};

const validateMessages = {
  required: 'សូមបញ្ចូល${label}',
};

const dateFormatList = ['DD/MM/YYYY', 'YYYY-MM-DD'];

const InlineItem: FC<any> = ({ children }) => {
  return (
    <Row gutter={16}>
      {children?.map((child: any, index: number) => (
        <Col key={index} xs={24} sm={24 / children?.length}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

const extractFileName = (info: UploadChangeParam<UploadFile>) => {
  if (info.file.status === 'done') {
    return info.file.response;
  }
};

const extractFileNames = (info: UploadChangeParam<UploadFile>) => {
  return info.fileList.reduce((accumalator, currentObj) => {
    if (currentObj.status === 'done') {
      accumalator.push(currentObj?.response);
    } else if (currentObj?.url) {
      accumalator.push(currentObj?.name);
    }
    return accumalator;
  }, [] as string[]);
};

export const MemberForm = (props: Props) => {
  let newInit = { ...props.initialValues };

  if (newInit?.dateOfBirth) {
    newInit.dateOfBirth = dayjs(newInit.dateOfBirth, dateFormatList[1]);
  }

  return (
    <>
      <Form
        name="memberForm"
        initialValues={newInit}
        disabled={props.disabled}
        form={props.form}
        validateMessages={validateMessages}
        layout="vertical"
        size="large"
        scrollToFirstError>
        <Form.Item
          name="photo"
          label="រូបថត"
          valuePropName="response"
          getValueFromEvent={extractFileName}>
          <AvatarUpload defaultImageUrl={props?.initialValues?.photo} />
        </Form.Item>

        <InlineItem>
          <Form.Item label="ឈ្មោះ" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="ភេទ" name="gender" rules={[{ required: true }]}>
            <Select options={genderOptions} />
          </Form.Item>
        </InlineItem>

        <InlineItem>
          <Form.Item
            label="ថ្ងៃ-ខែ-ឆ្នាំកំណើត"
            name="dateOfBirth"
            help="ឧ. 01/12/1980"
            // rules={[{ required: true, type: 'object' as const }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={dateFormatList}
              placeholder=""
            />
          </Form.Item>

          <Form.Item
            label="ទីកន្លែងកំណើត"
            name="placeOfBirth"
            help="ឧ. ភ្នំពេញ">
            <Input />
          </Form.Item>
        </InlineItem>

        <InlineItem>
          <Form.Item
            label="លេខទូរស័ព្ទ"
            name="phoneNumber"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="លំនៅដ្ឋានបច្ចុប្បន្ន" name="currentAddress">
            <Input />
          </Form.Item>
        </InlineItem>

        <InlineItem>
          <Form.Item label="លេខអត្តសញ្ញាណប័ណ្ណ" name="identityNumber">
            <Input placeholder="xxxxxxxxxxx" />
          </Form.Item>

          <Form.Item
            label="មុខរបរ (ការងារក្រៅម៉ោង)"
            name="occupation"
            help="ឧ. អ្នកគិតលុយ, អ្នកឆុងកាហ្វេ, ម៉ូតូឌុប ...">
            <Input />
          </Form.Item>
        </InlineItem>

        <Form.Item
          label="ប្រភេទសមាជិក"
          name="memberType"
          rules={[{ required: true }]}>
          <Select options={memberTypeOptions} />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.memberType !== curValues.memberType;
          }}>
          {instance => {
            const memberType = instance.getFieldValue('memberType');

            if (memberType === MemberType.Student) {
              return (
                <>
                  <InlineItem>
                    <Form.Item
                      label="គ្រឹះស្ថានសិក្សា"
                      name="university"
                      help="ឧ. សាកលវិទ្យាល័យភូមិន្ទភ្នំពេញ"
                      rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="ជំនាញសិក្សា"
                      name="major"
                      help="ឧ. គណនេយ្យករ"
                      rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </InlineItem>

                  <InlineItem>
                    <Form.Item
                      label="កម្រិតឆ្នាំសិក្សា"
                      name="yearOfStudy"
                      rules={[{ required: true }]}>
                      <Select options={yearOfStudyOptions} />
                    </Form.Item>

                    <Form.Item label="លេខប័ណ្ណសម្គាល់និស្សិត" name="studentId">
                      <Input placeholder="xxxxxxxxxxx" />
                    </Form.Item>
                  </InlineItem>

                  <InlineItem>
                    <Form.Item label="ឈ្មោះឪពុក" name="fatherName">
                      <Input />
                    </Form.Item>

                    <Form.Item label="ឈ្មោះម្ដាយ" name="motherName">
                      <Input />
                    </Form.Item>
                  </InlineItem>

                  <InlineItem>
                    <Form.Item name="isFatherDied" valuePropName="checked">
                      <Checkbox>ឪពុកស្លាប់</Checkbox>
                    </Form.Item>

                    <Form.Item name="isMotherDied" valuePropName="checked">
                      <Checkbox>ម្ដាយស្លាប់</Checkbox>
                    </Form.Item>

                    <Form.Item name="isScholarship" valuePropName="checked">
                      <Checkbox>សិស្សអាហារូបករណ៍</Checkbox>
                    </Form.Item>
                  </InlineItem>
                </>
              );
            } else if (memberType === MemberType.Poor_Citizen) {
              return (
                <>
                  <InlineItem>
                    <Form.Item
                      label="ឈ្មោះប្ដី-ប្រពន្ធ"
                      name="spouseName"
                      rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="មុខរបរប្ដី-ប្រពន្ធ"
                      name="spouseOccupation">
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="កូនក្នុងបន្ទុក"
                      name="numberOfChildren"
                      rules={[{ required: true }]}>
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </InlineItem>
                </>
              );
            } else {
              return <></>;
            }
          }}
        </Form.Item>
        <Form.Item
          name="attachment"
          label="ឯកសារបញ្ជាក់"
          valuePropName="response"
          getValueFromEvent={extractFileNames}>
          <FileUpload defaultFileUrl={props?.initialValues?.attachment} />
        </Form.Item>
      </Form>
    </>
  );
};
