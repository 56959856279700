import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Space, message, Table } from 'antd';
import { PopConfirmDelete, permissions_string } from '../../../components';
import { ColumnProps } from 'antd/es/table';
import { useState } from 'react';
import { IUser } from '../../../types/IUser';
import { deleteUser } from '../../../api';
import { UseTableScrollSize, useTableSrollSize } from '../../../hooks';
import { UserEdit } from './Edit/UserEdit';

export const useTableScrollProps: UseTableScrollSize = {
  tableId: 'user-table',
  filterId: 'user-filter',
};

type Props = {
  tableProps: any;
  refresh: () => void;
  getme: any;
};

export const UserTableList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser.User | null>(null);
  const scrollSize = useTableSrollSize(useTableScrollProps);

  const onEditClick = async (record: any) => {
    setSelectedUser(record);
    setIsModalOpen(true);
  };

  const onDeleteUser = async (id: string) => {
    try {
      setLoading(true);
      const response = await deleteUser(id);
      if (response?.data?.affected) {
        props.refresh();
      }
    } catch (error) {
      let err = error as any;
      message.error(err?.response?.data?.message);
      console.log('delete-member', error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    // form.resetFields();
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const onSaveFinished = (msg: string) => {
    msg && message.success(msg);
    setIsModalOpen(false);
    props.refresh();
  };

  const columns: ColumnProps<IUser.User>[] = [
    {
      title: 'ឈ្មោះជាភាសាខ្មែរ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'តួនាទី',
      dataIndex: 'roles',
      key: 'roles',
      render: record => {
        return record?.map((role: any) => role.name);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: record => {
        return (
          <div>
            {props?.getme?.permission_string?.find(
              (p: any) => p === permissions_string.editUser,
            ) ? (
              <Button
                style={{ margin: '0 10px' }}
                onClick={() => onEditClick(record)}
                icon={<EditOutlined />}
              />
            ) : null}

            {props?.getme?.permission_string?.find(
              (p: any) => p === permissions_string.deleteUser,
            ) ? (
              <PopConfirmDelete
                onDeleteClick={() => onDeleteUser(record.id)}
                loading={loading}
                title="អ្នកប្រើប្រាស់"
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        id={useTableScrollProps.tableId}
        rowKey="id"
        columns={columns}
        size="large"
        scroll={{
          y: scrollSize.y,
        }}
        {...props.tableProps}
      />
      {isModalOpen && (
        <Modal
          title="កែប្រែអ្នកប្រើប្រាស់"
          open={isModalOpen}
          onCancel={onCancel}
          footer={null}>
          <UserEdit
            onSaveFinished={onSaveFinished}
            selectedUser={selectedUser}
          />
        </Modal>
      )}
    </>
  );
};
