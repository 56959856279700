import { Navigate, useLocation } from 'react-router-dom';


export const PrivateRoute = ({ children, isProtected }: any) => {

  const location = useLocation();
  let state: any = {};

  if (location?.pathname !== '/logout') {
    state = { from: location };
  }

  if (isProtected) {
    return <Navigate to="/login" replace state={state} />;
  }

  return <>{children}</>;
};
