import { translations } from '.';
import { useLocalStorage } from '../hooks/useStorage';

export default function useTranslation() {
  const [language, setLanguage] = useLocalStorage('language', 'kh');
  const [fallbackLanguage, setFallbackLanguage] = useLocalStorage(
    'fallbackLanguage',
    'kh',
  );

  const translate = (key: string) => {
    const keys = key.split('.');

    return (
      getNestedTranslation(language, keys) ??
      getNestedTranslation(fallbackLanguage, keys) ??
      key
    );
  };

  return {
    language,
    setLanguage,
    fallbackLanguage,
    setFallbackLanguage,
    t: translate,
  };
}

function getNestedTranslation(language: string, keys: string[]) {
  return keys.reduce(
    (obj, key) => {
      return obj?.[key];
    },
    //@ts-ignore
    translations[language],
  );
}
