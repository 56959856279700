import { Form } from 'antd';
import { UserForm } from '../Form/UserForm';
import { editUser } from '../../../../api';

type Props = {
  onSaveFinished?: (msg: string) => void;
  selectedUser: any;
};

export const UserEdit = (props: Props) => {
  const [form] = Form.useForm();

  const onEditUser = async () => {
    form
      .validateFields()
      .then(async values => {
        const { role, ...rest } = values;
        form.resetFields();
        const res = await editUser(props?.selectedUser?.id, {
          ...rest,
          roles: [{ id: role }],
        });

        if (res?.data) {
          props.onSaveFinished &&
            props.onSaveFinished('កែប្រែអ្នកប្រើប្រាស់ជោគជ័យ');
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };
  return (
    <>
      <UserForm
        form={form}
        onSubmit={onEditUser}
        initialValues={{
          ...props?.selectedUser,
          role: props?.selectedUser?.roles?.[0]?.id,
        }}
      />
    </>
  );
};
