export function pick<T>(obj: T, keys: Array<keyof T>) {
  const ret = {};
  for (const key of keys) {
    //@ts-ignore
    ret[key] = obj[key];
  }
  return ret;
}

export function isDateString(dateStr: string) {
  return !isNaN(new Date(dateStr).getDate());
}
