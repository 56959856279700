import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { Button, message, Row, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { genderOptions, getLabelFromOptions, QR_PREFIX } from '../../constants';
import { getMemberById, registerAttendance } from '../../api';
import { useState } from 'react';
import { MemberImage } from '../../components';
import useTranslation from '../../translations/useTranslation';
import dayjs from 'dayjs';

const renderItem = (label: string, value: string) => {
  return (
    <div
      style={{
        textAlign: 'left',
        borderRadius: '10px',
        background: 'white',
        padding: 15,
        flex: 1,
        marginBottom: 10,
      }}>
      <div style={{ color: '#333', marginBottom: 5 }}>{label}</div>
      <div>{value}</div>
    </div>
  );
};

export const ScanQrPage = () => {
  const { t } = useTranslation();
  const [member, setMember] = useState<any>(null);

  const handleScan = async (result: IDetectedBarcode[]) => {
    if (result.length > 0) {
      const value = result[0].rawValue;
      const memberId = value.replace(QR_PREFIX, '');
      const res = await getMemberById(memberId);
      setMember(res?.data);
    }
  };

  const handleRegisterAttendant = async (memberId: string) => {
    const res = await registerAttendance(memberId);
    setMember(null);
    message.info('បានចុះវត្តមានរួចរាល់');
  };

  const hadCheckedIn = (attendances: any) => {
    const monthFormat = 'MMMM-YYYY';
    const now = dayjs().format(monthFormat);

    const found = attendances?.find((attend: any) => {
      return now === dayjs(attend.createdAt).format(monthFormat);
    });

    return Boolean(found);
  };

  return (
    <Content style={{ margin: '0px 1rem' }}>
      <Row style={{ padding: '1rem 0px' }} justify={'center'}>
        <div style={{ textAlign: 'center', width: '300px' }}>
          {member ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '1.5rem',
                }}>
                <MemberImage fileName={member?.photo} photoSize={150} />
              </div>
              {renderItem('ឈ្មោះ', member?.name)}
              {renderItem(
                'ភេទ',
                getLabelFromOptions(genderOptions, member?.gender),
              )}
              {renderItem('ប្រភេទសមាជិក', t(member?.memberType))}
              {renderItem('ថ្ងៃខែឆ្នាំកំណើត', member?.dateOfBirth)}
              {renderItem('ទីកន្លែងកំណើត', member?.placeOfBirth)}
              {hadCheckedIn(member?.attendances) ? (
                <Tag
                  style={{ display: 'block', textAlign: 'center', padding: 15 }}
                  color="red">
                  បានចុះវត្តមានរួចរាល់
                </Tag>
              ) : (
                <Button
                  block
                  type="primary"
                  onClick={() => handleRegisterAttendant(member?.id)}>
                  ចុះវត្តមាន
                </Button>
              )}
            </div>
          ) : (
            <Scanner onScan={handleScan} allowMultiple />
          )}
        </div>
      </Row>
      {member && (
        <Row>
          <Button
            block
            style={{ marginBottom: 10 }}
            onClick={() => {
              setMember(null);
            }}>
            Scan another QR
          </Button>
        </Row>
      )}
    </Content>
  );
};
