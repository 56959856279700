export const title = {
  info: 'ព័ត៌មានជនងាយរងគ្រោះ',
  event: 'កម្មវិធីគ្រួសារយើង',
};

export const label = {
  name: 'គោត្តនាម-នាម',
  gender: 'ភេទ',
  phone: 'លេខទំនាក់ទំនង',
  memberType: 'ប្រភេទសមាជិក',
};