import {
  getMembers,
  getPoorMembers,
  getStudentMembers,
  getUsers,
} from '../api';

type GetTableParams = {
  current: number;
  pageSize: number;
  sorter?: any;
  filter?: any;
  extra?: any;
};

export const downloadQRCode = (fileName = 'QRCode', elementId = 'memberQr') => {
  const canvas = document
    .getElementById(elementId)
    ?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL();
    const a = document.createElement('a');
    a.download = `${fileName}.png`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const getTableData = (
  fetchingFunction: (query?: string) => Promise<any>,
  params: GetTableParams,
  formData: any,
  other?: any,
): Promise<any> => {
  const { field = 'memberType', order } = params?.sorter || {};

  const sort = { [field]: order === 'ascend' ? 'ASC' : 'DESC' };

  let query = `page=${params.current}&limit=${
    params.pageSize
  }&order=${JSON.stringify(sort)}`;

  if (formData) {
    Object?.entries(formData).forEach(([key, value]) => {
      if (value) {
        query += `&${key}=${value}`;
      }
    });
  }

  return fetchingFunction(query).then(res => {
    return {
      total: res.total,
      list: res.data,
    };
  });
};

export const getMembersTableData = (
  params: GetTableParams,
  formData: any,
  other?: any,
): Promise<any> => {
  return getTableData(getMembers, params, formData, other);
};

export const getStudentMembersTableData = (
  params: GetTableParams,
  formData: any,
  other?: any,
): Promise<any> => {
  return getTableData(getStudentMembers, params, formData, other);
};

export const getPoorMembersTableData = (
  params: GetTableParams,
  formData: any,
  other?: any,
): Promise<any> => {
  return getTableData(getPoorMembers, params, formData, other);
};

export const getUsersTableData = (
  params: GetTableParams,
  formData: any,
  other?: any,
): Promise<any> => {
  return getTableData(getUsers, params, formData, other);
};
