import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IUser } from '../types/IUser';
import { message } from 'antd';
import { getMe, login, validateToken } from '../api';

type UserState = {
  token?: string;
  login: (data: IUser.LoginBody) => Promise<void>;
  logout: () => void;
};

export const userStore = create<UserState>()(
  persist(
    (set, get) => ({
      token: undefined,
      login: async data => {
        const response = await login(data);

        if (response?.data?.token?.token) {
          message.success(`ចូលប្រើប្រាស់ដោយជោគជ័យ`);
          set({ token: response?.data?.token?.token });
        } else {
          message.error('លេខកូដមិនត្រឹមត្រូវ!');
        }
      },

      logout: () => {
        set({ token: undefined });
      },
    }),
    {
      name: 'token',
      partialize: ({ token }) => ({ token }),
    },
  ),
);

export const useUserToken = () => userStore(({ token }) => token);
export const useLogin = () => userStore(({ login }) => login);
export const useLogout = () => userStore(({ logout }) => logout);
