import axios from 'axios';
import { API_URL } from '../constants';
import { userStore } from '../store';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  baseURL: API_URL,
  method: 'GET',
});

instance.interceptors.request.use(
  function (config) {
    const store = userStore?.getState();
    const token = store?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);

    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.data.error && +response.data.error !== 0) {
      return Promise.reject(response.data);
    }
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // const config = error.config;

    if (!error.response) {
      return;
    }

    return Promise.reject(error);
  },
);

export { instance as request };
