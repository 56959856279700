import { Button, Descriptions, QRCode } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import {
  FileUploadViewer,
  MemberImage,
  PDFMemberInfo,
} from '../../../../components';
import {
  genderOptions,
  getLabelFromOptions,
  QR_PREFIX,
  yearOfStudyOptions,
} from '../../../../constants';
import { uyfc } from '../../../../images';
import useTranslation from '../../../../translations/useTranslation';
import { MemberType } from '../../../../types/IMember';

import { downloadQRCode, pick } from '../../../../utils';
import { formattedDate } from '../../../../utils/date';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useState } from 'react';

type Props = {
  memberInfo: any;
};

const { Item } = Descriptions;

const poorCitizenFields = [
  'spouseName',
  'spouseOccupation',
  'numberOfChildren',
];
const studentFields = [
  'university',
  'major',
  'yearOfStudy',
  'studentId',
  'fatherName',
  'isFatherDied',
  'motherName',
  'isMotherDied',
  'isScholarship',
];

const photoSize = 140;

export const MemberDetail = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const commonFields = pick(props?.memberInfo, [
    'name',
    'gender',
    'dateOfBirth',
    'placeOfBirth',
    'phoneNumber',
    'currentAddress',
    'identityNumber',
    'occupation',
    ...(props?.memberInfo?.memberType === MemberType.Student
      ? studentFields
      : poorCitizenFields),
    'attachment',
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '1.5rem',
        }}>
        <MemberImage fileName={props?.memberInfo?.photo} photoSize={150} />
      </div>

      <Descriptions layout="vertical" colon={false} column={2}>
        {Object.entries(commonFields).map(
          ([key, value]: [key: string, value: any]) => {
            switch (key) {
              case 'gender':
                value = getLabelFromOptions(genderOptions, value);
                break;
              case 'yearOfStudy':
                value = getLabelFromOptions(yearOfStudyOptions, value);
                break;
              case 'dateOfBirth':
                value = value ? formattedDate(value) : 'N/A';
                break;
              case 'isFatherDied':
              case 'isMotherDied':
                value = value ? 'ស្លាប់' : 'នៅរស់';
                break;
              case 'isScholarship':
                value = value ? 'អាហារូបករណ៍' : 'បង់ថ្លៃ';
                break;
              case 'attachment':
                value = value ? (
                  <FileUploadViewer fileName={props?.memberInfo?.attachment} />
                ) : (
                  'N/A'
                );
                break;
              default:
                break;
            }

            return (
              <Item
                key={key}
                label={t(key)}
                style={{ fontSize: '1.5rem', color: '#5f5f5f' }}
                contentStyle={{ fontSize: '1rem', fontWeight: 800 }}>
                {value || 'N/A'}
              </Item>
            );
          },
        )}
      </Descriptions>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <div key="memberQr" id="memberQr">
          <QRCode
            style={{ alignSelf: 'center' }}
            value={`${QR_PREFIX}${props?.memberInfo?.id}`}
          />
        </div>

        <PDFDownloadLink
          document={<PDFMemberInfo memberInfo={props?.memberInfo} />}
          fileName={props?.memberInfo?.name}>
          {loading ? (
            <Button
              style={{ marginTop: '1rem' }}
              size="large"
              type="primary"
              danger>
              កំពុងទាញយក...
            </Button>
          ) : (
            <Button style={{ marginTop: '1rem' }} size="large" type="primary">
              ទាញយក
            </Button>
          )}
        </PDFDownloadLink>
      </div>
    </>
  );
};
