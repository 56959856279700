import { ICommon } from './ICommon';

export enum MemberType {
  Student = 'Student',
  Poor_Citizen = 'Poor_Citizen',
}

export declare namespace IMember {
  export interface Info extends ICommon.ResponseAudit {
    id: string;
    name: string;
    gender: string;
    dateOfBirth: string;
    photo: string;
    phoneNumber: string;
    memberType: MemberType;
    university: ICommon.MaybeNull<string>;
    studentId: ICommon.MaybeNull<string>;
    identityNumber: ICommon.MaybeNull<string>;
    major: ICommon.MaybeNull<string>;
    yearOfStudy: ICommon.MaybeNull<string>;
    isScholarship?: boolean;
    currentAddress: ICommon.MaybeNull<string>;
    fatherName: ICommon.MaybeNull<string>;
    isFatherDied?: boolean;
    motherName: ICommon.MaybeNull<string>;
    isMotherDied?: boolean;
    occupation: ICommon.MaybeNull<string>;
    placeOfBirth: ICommon.MaybeNull<string>;
    spouseName: ICommon.MaybeNull<string>;
    numberOfChildren: ICommon.MaybeNull<number>;
    spouseOccupation: ICommon.MaybeNull<string>;
  }

  export interface AttendInfo extends Info {
    attendances: Array<{ createdAt: string }>;
  }
}
