import { RouteObject } from 'react-router-dom';
import App from '../App';
import {
  AttendanceListPage,
  MemberListPage,
  MemberPage,
  NoFoundPage,
  PoorAttendanceListPage,
} from '../pages';
import { AdminRoot } from '../pages/Admin/AdminRoot';
import { LoginPage } from '../pages/Admin/Authentication';
import { SearchPage } from '../pages/Search/SearchPage';
import { MemberType } from '../types/IMember';
import { UsersListPage } from '../pages/Admin/UsersList';
import { ScanQrPage } from '../pages/ScanQr';

type CustomRouteObject = {
  selectable?: boolean;
} & RouteObject;

export const adminRoutes: any[] = [
  {
    path: 'member-list',
    element: <MemberListPage />,
    selectable: true,
  },
  {
    path: 'attendance',
    selectable: false,
    children: [
      {
        path: '/admin/attendance/student',
        element: <AttendanceListPage memberType={MemberType.Student} />,
        selectable: true,
      },
      {
        path: '/admin/attendance/poor',
        element: <PoorAttendanceListPage />,
        selectable: true,
      },
    ],
  },
  {
    path: 'users',
    element: <UsersListPage />,
    selectable: true,
  },
  {
    path: 'scan-qr',
    element: <ScanQrPage />,
    selectable: true,
  },
];

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'search',
        element: <SearchPage />,
      },
      {
        path: 'self-register',
        element: <MemberPage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'admin',
        element: <AdminRoot />,
        children: [
          {
            index: true,
            element: <MemberListPage />,
          },
          ...adminRoutes,
        ],
      },
    ],
  },
  { path: '*', element: <NoFoundPage /> },
];
