import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Menu, Popconfirm } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { useCallback, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Logo, permissions_string } from '../../components';
import { adminRoutes } from '../../routes/routes';
import { useLogout } from '../../store';
import useTranslation from '../../translations/useTranslation';
import {
  changeEnablesStudentEdit,
  getEnableStudentEdit,
  getMe,
} from '../../api';

export const AdminRoot = () => {
  const [isEnableEdit, setIsEnableEdit] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [getme, setGetme] = useState<any>();

  const { t } = useTranslation();
  const location = useLocation();
  const logout = useLogout();

  useEffect(() => {
    async function getEnable() {
      const response = await getEnableStudentEdit();
      if (response?.data?.value === 'true') {
        setIsEnableEdit(true);
      } else if (response?.data?.value === 'false') {
        setIsEnableEdit(false);
      }
    }
    async function getProfile() {
      const response = await getMe();
      setGetme(response?.data);
    }
    getProfile();
    getEnable();

    // mobile screen
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event: any) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const mapRoutesToMenu: any = (routes: any[]) => {
    return routes?.map((route: any) => {
      let tmpChild;
      if (route?.children) {
        tmpChild = mapRoutesToMenu(route?.children);
      }
      return {
        label: route?.selectable ? (
          <Link to={route.path}>{t(route.path)}</Link>
        ) : (
          `${t(route.path)}`
        ),
        // label: route?.path,
        key: route?.path,
        children: tmpChild,
      };
    });
  };

  const defaultSelectedKeys = useCallback(() => {
    const splittedPath = location.pathname.split('/');
    const selectedKey = splittedPath[splittedPath.length - 1];
    return selectedKey === 'admin' ? 'member-list' : selectedKey;
  }, [location]);

  const onEnableEdit = () => {
    changeEnablesStudentEdit('true');
    setIsEnableEdit(true);
  };

  const onDisableEdit = () => {
    changeEnablesStudentEdit('false');
    setIsEnableEdit(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header
        style={{
          zIndex: 999,
          width: '100%',
          position: 'sticky',
          top: 0,
          padding: '0px 1rem',
          background: '#111a2c',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <Logo width={55} />
          {isMobile ? (
            <div style={{ marginTop: 0, marginLeft: 10 }}>
              <Button onClick={showDrawer}>
                <MenuUnfoldOutlined />
              </Button>
              <Drawer placement="left" onClose={onClose} open={open}>
                <Menu
                  style={{
                    margin: '0 1rem',
                    background: 'transparent',
                    width: '100%',
                  }}
                  defaultSelectedKeys={[defaultSelectedKeys()]}
                  items={mapRoutesToMenu(adminRoutes)}
                  onClick={() => setOpen(false)}></Menu>
              </Drawer>
            </div>
          ) : (
            <Menu
              style={{
                margin: '0 1rem',
                background: 'transparent',
                color: 'white',
                width: '100%',
              }}
              mode="horizontal"
              theme="dark"
              defaultSelectedKeys={[defaultSelectedKeys()]}
              items={mapRoutesToMenu(adminRoutes)}></Menu>
          )}
        </div>
        <div style={{ alignSelf: 'center' }}>
          {getme?.permission_string?.find(
            (p: any) => p === permissions_string.enableStudent,
          ) ? (
            <Popconfirm
              title="អនុញ្ញាតឱ្យនិស្សិតកែប្រែព័ត៌មាន"
              description={`តើអ្នកពិតជាចង់${
                isEnableEdit ? 'បិទ' : 'បើក'
              }ដំណើរការមែនទេ?`}
              onConfirm={isEnableEdit ? onDisableEdit : onEnableEdit}
              okText={`${isEnableEdit ? 'បិទ' : 'បើក'}`}
              okButtonProps={{ danger: isEnableEdit ? true : false }}
              cancelText="ទេ">
              <Button
                type="primary"
                icon={<SettingOutlined />}
                style={{
                  marginRight: 10,
                  backgroundColor: isEnableEdit ? '#52c41a' : '#ff4d4f',
                }}
              />
            </Popconfirm>
          ) : null}
          <Popconfirm
            title="ចាកចេញ"
            description="តើអ្នកពិតជាចង់ចាកចេញមែនទេ?"
            onConfirm={logout}
            okText="ចាកចេញ"
            okButtonProps={{ danger: true }}
            cancelText="ទេ">
            <Button type="primary" danger icon={<LogoutOutlined />} />
          </Popconfirm>
        </div>
      </Header>
      <Content style={{ margin: '0px 1rem' }}>
        <Outlet />
      </Content>
    </>
  );
};
