import { Image, ImageProps, List } from 'antd';
import { FILE_DOWNLOAD_URL } from '../../constants';
import { FilePdfTwoTone } from '@ant-design/icons';

type Props = {
  fileName?: string[];
  photoSize?: number;
} & Omit<ImageProps, 'src'>;

export const FileUploadViewer = ({
  fileName,
  photoSize = 40,
  ...props
}: Props) => {
  return (
    <List
      grid={{ gutter: 16 }}
      dataSource={fileName}
      renderItem={fileName => {
        return (
          <List.Item>
            <Image
              {...props}
              src={`${FILE_DOWNLOAD_URL}/${fileName}?dim=${photoSize}x${photoSize}`}
              preview={{
                src: `${FILE_DOWNLOAD_URL}/${fileName}`,
              }}
              style={{ borderRadius: 5, width: photoSize }}
            />
          </List.Item>
        );
      }}
    />
  );
};
