import { message } from 'antd';
import { RcFile } from 'antd/es/upload';

export const requiredRuleLabel = (label: string) => {
  return {
    label,
    rules: [
      {
        required: true,
        message: `សូមបញ្ចូល${label}`,
      },
    ],
  };
};

export const beforeImageUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error('Image must smaller than 20MB!');
  }
  return isJpgOrPng && isLt20M;
};
