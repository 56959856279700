import { UserForm } from '../Form/UserForm';
import { addUser } from '../../../../api';
import { FormInstance } from 'antd';

type Props = {
  form: FormInstance<any>;
  onSaveFinished?: (msg: string) => void;
};

export const UserAdd = (props: Props) => {
  const { form } = props;
  const initialValues = {};

  const onUserAdd = async () => {
    form
      .validateFields()
      .then(async values => {
        const { role, ...rest } = values;
        form.resetFields();
        const res = await addUser({ ...rest, roles: [{ id: role }] });

        if (res?.data) {
          props.onSaveFinished &&
            props.onSaveFinished('បង្កើតអ្នកប្រើប្រាស់ជោគជ័យ');
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };
  return (
    <>
      <UserForm
        form={form}
        onSubmit={onUserAdd}
        initialValues={initialValues}
      />
    </>
  );
};
