import { Button, Form, FormInstance, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { getRole } from '../../../../api';

const { Option } = Select;

type Props = {
  form: FormInstance<any>;
  onSubmit: () => void;
  initialValues: any;
};

const validateMessages = {
  required: 'សូមបញ្ចូល${label}',
};

export const UserForm = (props: Props) => {
  const { initialValues } = props;
  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    const role = await getRole();
    setRoles(role?.data);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <Form
        // key={initialValues?.id}
        name="usersForm"
        style={{ maxWidth: 600, marginTop: 30 }}
        initialValues={{ ...initialValues }}
        validateMessages={validateMessages}
        onFinish={props.onSubmit}
        form={props.form}
        layout="vertical"
        autoComplete="off">
        <Form.Item
          label="ឈ្មោះជាភាសាខ្មែរ"
          name="name"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="តួនាទី" name="role" rules={[{ required: true }]}>
          <Select defaultValue="ជ្រើសរើសតួនាទី" optionLabelProp="name">
            {roles.map((role: any) => (
              <Option key={role?.id} value={role?.id} name={role?.name}>
                {role?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        {!initialValues?.id ? (
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
        ) : (
          ''
        )}

        <Form.Item style={{ textAlign: 'end' }}>
          <Button type="primary" htmlType="submit">
            រក្សាទុក
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
